.captcha-box {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.gc_captcha_input + .captcha-box {
	margin-top: 10px;
}

.captcha-box .captcha-refresh-btn {
	margin-left: 10px;
	cursor: pointer;
}
